
















































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import Office from "../../components/inputs/office.vue";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import EventAutoComplete from "@/views/components/inputs/event.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";
import Country from "@/views/components/inputs/country.vue";
import { ContractApi } from "@/networks/ContractApi";
import FileUploader from "@/views/components/inputs/fileUploader.vue";
import DatePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";

@Component({
  components: {
    FileUploader,
    DatetimePicker,
    office: Office,
    UserAuto: UserAutoComplete,
    EventAuto: EventAutoComplete,
    country: Country,
    DatePicker
  }
})
export default class Contract extends Vue {
  private types = Enum.MigrationMethods;
  private paymentTypes = Enum.TypePayment;
  private typeCurrency = Enum.currencies;
  private paymentNames = ["پیش پرداخت قرارداد", "قسط قرارداد", "تسویه قرارداد"];
  private staff = [];
  private file: any = [];
  private payment: any = [];
  private form: Record<string, any> = {};
  private errors: Record<string, Array<string>> = {};
  private loading = true;

  async mounted() {
    const res = await new ContractApi().getItem(this.$route.params.id);
    const data = res.data.data;
    this.form.migration_method = data.migration_method;
    this.form.countries = data.countries;
    this.form.company_commitment = data.company_commitment;
    this.form.user_commitment = data.user_commitment;
    this.form.user_special_conditions = data.user_special_conditions;
    this.form.office_id = data.office_id;
    this.loading = false;
  }

  public async submit(): Promise<void> {
    try {
      const form = Object.assign({}, this.form);
      form.countries = "";
      console.log(this.form.countries.length);
      for (let i = 0; i < this.form.countries.length; i++) {
        if (i == 0) {
          form.countries = `${this.form.countries[i].id}`;
        } else {
          form.countries += `,${this.form.countries[i].id}`;
        }
      }
      const res = await new ContractApi().editItem(this.$route.params.id, form);
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

}
